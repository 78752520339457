body,
html {
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  height: 100%;
}
/* HERO START XXXXXXXXXXXXXXX*/
.hero-image-intro {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../public/images/blue-black-digital-binary-technology-hero-header.jpg");
  height: 50%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  position: relative;
}

.hero-image-spacer {
  background-color: #ffffff;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("");
  height: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  background-blend-mode: screen;
  /* Add the blur effect */
}

.hero-text {
  /* text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white; */
}

/* HERO END XXXXXXXXXXXXXXX*/

/* GAME ANIMATIONS START XXXXXXXXXXXXXXX*/
.strike-opp,
.strike-me {
  width: 50%;
  height: 50%;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: red;
  transition: all 0.5s ease;
  /* animation-name: strike;
  animation-duration: 0.2s;
  animation-play-state: running; */
}

/* .strike-me {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: blue;
  transition: all 1s ease;
} */

.disappear {
  width: 0px !important;
  height: 0px !important;
  transform: rotate(360deg) !important;
  top: 50%;
  left: 50%;
}

/* GAME ANIMATIONS END XXXXXXXXXXXXXXXXXX*/

/* GAME ANIMATIONS START */
/* GAME ANIMATIONS START */
/* GAME ANIMATIONS START */
/* GAME ANIMATIONS START */

div#strike-child-me,
div#strike-child-opp {
  background-color: rgb(0, 255, 115);
  width: 0px;
  height: 0px;
  position: relative;
  animation-name: strike;
  animation-duration: 0.2s;
  animation-play-state: paused;
}

@keyframes strike {
  0% {
    width: 0px;
    height: 0px;
    top: 0px;
    left: 0px;
    opacity: 0;
    transform: rotate(0deg);
    /* transform: rotateY(0deg); */
  }

  1% {
    width: 150px;
    height: 150px;
    top: 10%;
    left: 10%;
    opacity: 0;
  }
  30% {
    width: 150px;
    height: 150px;
    top: 10%;
    left: 10%;
    opacity: 1;
  }
  75% {
    width: 50px;
    height: 50px;
    top: 30%;
    left: 30%;
    opacity: 1;
  }
  100% {
    width: 0px;
    height: 0px;
    top: 40%;
    left: 40%;
    opacity: 0;
    transform: rotate(360deg);
    /* transform: rotateY(180deg); */
  }
}

div#player-board-me,
div#player-board-opp {
  animation-name: shake;
  animation-duration: 0.5s;
  animation-play-state: paused;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
/* GAME ANIMATIONS END */
/* GAME ANIMATIONS END */
/* GAME ANIMATIONS END */
/* GAME ANIMATIONS END */

.home-spacer {
  aspect-ratio: 960/70;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url("../public/images/layered-waves-haikei-x1.svg");
}

/* .layer1 {
  background-image: url("../public/images/layered-waves-haikei.svg");
} */

.spacer {
  aspect-ratio: 960/30;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer2 {
  background-image: url("../public/images/layered-waves-haikei-2.svg");
}

.spacer-bottom {
  aspect-ratio: 960/100;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer3 {
  background-image: url("../public/images/layered-waves-haikei-3.svg");
  background-repeat: repeat-x;
}

.layer4 {
  background-image: url("../public/images/layered-waves-haikei-4.svg");
  height: 100px;
}

.layer-footer {
  background-image: url("../public/images/layered-waves-haikei-footer.svg");
}

.dropdown-header {
  border: 0px yellow solid;
  padding-left: 18px;
}

.dropdown-additional-info {
  text-align: right;
  margin-right: 15px;
  font-size: 0.8rem;
}

.nav-link {
  padding-bottom: 15px;
  padding-top: 15px;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.navbar-nav-scroll {
  --bs-scroll-height: 70vh;
}

.dropdown-item {
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 3px;
  padding-left: 5px;
  padding-right: 5px;
}

.dropdown-item.dropdown-seperator {
  border-top: 0px solid #36454f;
}

span.dropdown-item a.nav-link {
  padding-bottom: 8px;
  padding-top: 8px;
}

.dropdown .dropdown-menu {
  display: block;
  /* visibility: hidden; */
  opacity: 1;
  max-width: 98% !important;
  /* left: 0 !important; */
  border-radius: 5px;
  border: 0px solid white;
  /* margin: 5px; */
  padding: 5px;

  -webkit-transition: all 400ms ease-in;
  -moz-transition: all 400ms ease-in;
  -ms-transition: all 400ms ease-in;
  -o-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
}

.dropdown-menu {
  opacity: 0.95;
  max-width: 98% !important;
  left: 0;
  border-radius: 0;
}

a.nav-link.comment-link-text,
div.nav-link.comment-link-text,
div.ms-auto.comment-link-text {
  font-size: 0.7rem;
  padding-right: 2px;
}

.main-sub-title {
  font-size: 1.2rem;
  font-weight: 300;
}

.other-sub-title {
  font-size: 1rem;
  font-weight: 300;
}

.main-title {
  /* color: #ffffff; */
  font-weight: 400;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 3rem;
  margin-bottom: 20px;
}

.other-title {
  /* color: #ffffff; */
  font-weight: 300;
  text-align: center;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
  font-size: 2rem;
  /* margin-bottom: 20px; */
}

.main-hero {
  background-color: #212529;
  height: 100vh;
  border: 0px solid red;

  background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url("../public/images/orientamento-mmm-webpage-3.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* background-attachment: fixed; */
  position: relative;
}

.main-hero-btns {
  width: 100%;
  height: 60px;
  border-radius: 30px;
  margin-bottom: 20px;
}

.main-hero-container {
  top: 15%;
}

.card.home {
  border: 1px solid #dddddd;
  padding-left: 0px;
  padding-bottom: 7px;
}

.card.home h4 {
  border: 0px solid #00ffaa;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.content-main-image {
  max-width: 100%;
  border: 0px solid red;
}

div#custom-cards .col {
  border: 0px solid #ff0000;
  margin-top: 0;
  padding: 3px;
}

div.card span {
  border: 0px solid #ff0000;
  font-weight: bolder;
  font-size: 0.8rem;
}

.alert-login {
  width: 90%;
}

.my-intro-div {
  font-size: 0.8rem;
}

.card-img-top {
  height: 110px;
  width: 100%;
  object-fit: cover;
}

.card-img-top-hp {
  height: 220px;
  width: 100%;
  object-fit: cover;
}

.item-card {
  background: hsla(186, 33%, 94%, 1);

  background: linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(222, 26%, 46%, 1) 100%
  );

  background: -moz-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(222, 26%, 46%, 1) 100%
  );

  background: -webkit-linear-gradient(
    90deg,
    hsla(186, 33%, 94%, 1) 0%,
    hsla(222, 26%, 46%, 1) 100%
  );

  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#EBF4F5", endColorstr="#B5C6E0", GradientType=1 );
}

/*

MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START
MEDIA QUERIES START

*/

/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 575px) {
  .navLogo {
    border-top: 1px solid white;
  }

  .nav-item.dropdown {
    padding-bottom: 0px;
    border-bottom: 0px solid #333333;
  }
}

/* Small devices (portrait tablets and large phones, 576px and up) */
@media only screen and (min-width: 576px) {
  .navLogo {
    border-top: 1px solid blue;
  }

  .other-title {
    font-size: 2.5rem;
  }

  .main-hero-btns {
    width: 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .navLogo {
    border-top: 1px solid green;
  }

  .other-title {
    font-size: 3rem;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .navLogo {
    border-top: 1px solid yellow;
  }

  .nav {
    z-index: 999;
  }

  .dropdown .dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    width: 100% !important;
    position: fixed !important;
    left: 0 !important;
    margin-top: 20px;
    background-color: transparent;

    -webkit-transition: all 100ms ease-in;
    -moz-transition: all 100ms ease-in;
    -ms-transition: all 100ms ease-in;
    -o-transition: all 100ms ease-in;
    transition: all 100ms ease-in;
  }

  .dropdown:hover .dropdown-menu {
    display: block;
    opacity: 1;
    width: 100%;
    position: fixed;
    left: 0;
    border-radius: 0;
  }

  .dropdown-menu-show.dropdown-menu {
    visibility: visible;
    opacity: 1;
    top: 56px;
    width: 100%;
    position: fixed;
    left: 0px;
    border-radius: 0;
    border: 0px solid rgb(255, 0, 0);
  }

  .dropdown-menu-hide.dropdown-menu {
    visibility: hidden;
    opacity: 0;
    top: 56px;
    width: 100%;
    position: fixed;
    left: 0px;
    border-radius: 0;
    border: 0px solid black;
  }

  #dropdown-bg {
    display: block;
    width: 100% !important;
    height: 200px;
    position: fixed !important;
    left: 0 !important;
    margin-top: 20px;

    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -ms-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
    transition: all 200ms ease-out;
  }

  .dropdown-menu-show2 {
    visibility: visible;
    z-index: 0;
    background-color: #212529;
    top: 56px;
  }

  .dropdown-menu-hide2 {
    visibility: hidden;
    z-index: -999;
    background-color: #212529;
    top: -150px;
  }

  .other-sub-title {
    font-size: 1.2rem;
  }

  .main-hero-btns {
    width: 60%;
  }

  .my-intro-div {
    font-size: 1rem;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .navLogo {
    border-top: 1px solid orange;
  }
  .alert-login {
    width: 70%;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media only screen and (min-width: 1400px) {
  .navLogo {
    border-top: 1px solid pink;
  }

  .other-title {
    font-size: 4rem;
  }

  .main-hero-container {
    top: 25%;
  }

  .main-hero-btns {
    max-width: 500px;
  }

  .alert-login {
    width: 50%;
  }

  .my-intro-div {
    font-size: 0.9rem;
  }
}

/* UNCOMMENT BELOW TO TURN OFF COLORED LINES TO DISTINGUISH MEDIA QUERY SCREEN SIZES*/
.navLogo {
  border-top: 0px solid pink;
}
